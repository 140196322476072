<template>
  <div style="position: relative; width: 100%; height: 100%">
    <Chart
      v-if="mapIndex !== 1"
      :cdata="cdata"
      :jsonMap="jsonMap"
      :mapIndex="mapIndex"
      v-on:change="change"
    />
    <div v-else id="container"></div>
  </div>
</template>

<script>
import Chart from "./chart.vue";
import guizhou from "@/common/map/guizhou.json";
import qinxinan from "@/common/map/qinxinan.json";
import puan from "@/common/map/puan.json";

export default {
  data() {
    return {
      cdata: [
        {
          // 名字需要与 “common/map/putian.js” 地图数据文件里面定义的一一对应，不能是 “福州” 或者 “闽” 之类的缩写
          name: "普安县",
          value: 10,
          elseData: {
            // 这里放置地图 tooltip 里想显示的数据
          },
        },
        {
          name: "兴仁市",
          value: 9,
        },
        {
          name: "荔城区",
          value: 8,
        },
        {
          name: "秀屿区",
          value: 7,
        },
        {
          name: "涵江区",
          value: 6,
        },
      ],
      jsonMap: [], // 数据对象
      // mapIndex: 0, // mapType 设置指定离线地图
      // marker: {
      //   公交公司: [104.973881, 25.779932,40],
      //   惠民大道: [104.947304, 25.785784,107],
      //   恵民小区: [104.946278, 25.786978,105],
      //   恒丰路段: [104.972696, 25.777887,77],
      //   环城东路: [104.96579, 25.787922,51],
      //   政务中心侧: [104.946524, 25.786228,39],
      //   文笔路: [104.964276, 25.788976,35],
      //   农商行: [104.962301, 25.790112,24],
      //   交通路: [104.95324, 25.78429,20],
      //   公安路段: [104.97382, 25.782571,51],
      // },
    };
  },
  props: {
    mapIndex: {
      type: Number,
      default: 0,
    },
    marker: {
      type: Object,
      default: () => ({}),
    },
    numTotal: {
      type: Number,
      default: 500,
    },
    test: {
      type: Number,
    },
  },
  components: {
    Chart,
  },
  created() {
    console.log(">>>>>>>>>>>>>>>>>>>>>>>>>>>", this.numTotal);
    this.jsonMap = [
      {
        data: guizhou, //地图数据
        seriesName: "泊位数",
        mapSite: "贵州", //对应地图数据JSON文字
        cdata: [
          { name: "黔西南布依族苗族自治州", value: this.numTotal },
          { name: "安顺市", value: 0 },
          { name: "六盘水市", value: 0 },
          { name: "毕节市", value: 0 },
          { name: "遵义市", value: 0 },
          { name: "铜仁市", value: 0 },
          { name: "贵阳市", value: 0 },
          { name: "黔东南苗族侗族自治州", value: 0 },
          { name: "黔南布依族苗族自治州", value: 0 },
        ], //地图地块弹窗数据
      },
      {
        data: qinxinan,
        seriesName: "泊位数",
        mapSite: "黔西南布依族苗族自治州",
        cdata: [
          { name: "普安县", value: this.numTotal },
          { name: "晴隆县", value: 0 },
          { name: "兴仁市", value: 0 },
          { name: "贞丰县", value: this.numTotal },
          { name: "望谟县", value: 0 },
          { name: "册亨县", value: this.numTotal },
          { name: "安龙县", value: 0 },
          { name: "兴义市", value: 0 },
        ],
      },
      {
        data: puan,
        seriesName: "泊位数",
        mapSite: "普安县",
        cdata: [{ name: "普安县", value: this.numTotal }],
      },
    ];

    //循环遍历注册地图
    for (let index in this.jsonMap) {
      this.$echarts.registerMap(
        this.jsonMap[index].mapSite,
        this.jsonMap[index].data
      );
    }
  },
  watch: {
    mapIndex(newData) {
      if (newData === 1) {
        setTimeout(() => {
          this.mapFun();
        }, 100);
      }
    },
  },
  methods: {
    mapFun() {
      let that = this;
      let markerList = [];
      let map = new AMap.Map("container", {
        zoom: 4, //级别
        center: [107.955347, 30.786404], //中心点坐标
      });
      console.log("this.marker", this.marker);
      for (let i in this.marker) {
        let item = this.marker[i];
        // console.log(item, "itemitem");
        let marker = new AMap.Marker({
          position: new AMap.LngLat(Number(item[0]), Number(item[1])),
          offset: new AMap.Pixel(-10, -10),
          title: i,
          label: {
            offset: new AMap.Pixel(0, 20), //设置文本标注偏移量
            content: `${i}泊位数：${item[2]}<br/>使用中：${item[3]}<br/>空闲中：${item[4]}`, //设置文本标注内容
            direction: "top", //设置文本标注方位
          },
          width: 30,
          height: 30,
        });

        markerList.push(marker);
      }

      map.add(markerList);
    },
    change(index) {
      this.$emit("changes", index);
      // this.mapIndex = index;
    },
  },
};
</script>

<style lang="scss" scoped>
#container {
  width: 90%;
  height: 90%;
  position: absolute;
  top: 8%;
  left: 5%;
  z-index: 1111;
  border-radius: 10px;
}
.amap-marker-label {
  // background-color: rgba(0,0,0,.5);
  // color:#333;
  // border:0px;
  position: absolute;
  z-index: 2;
  border: 1px solid #ccc;
  background-color: white;
  white-space: nowrap;
  cursor: default;
  padding: 3px;
  font-size: 12px;
  line-height: 14px;
}
</style>
