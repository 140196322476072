<template>
  <!-- ref="centreLeft2" -->
  <div ref="centreLeft2">
    <!-- width="4.125rem" -->
    <Echart
      id="centreLeft2Chart"
      ref="centreLeft2ChartRef"
      :options="options"
      height="4.5rem"
      width="5.8rem"
    ></Echart>
  </div>
</template>

<script>
import Echart from "@/common/echart";

export default {
  data() {
    return {
      options: {},
    };
  },
  components: {
    Echart,
  },
  props: {
    jsonMap: {
      type: Array,
      default: () => [],
    },
    mapIndex: {
      type: Number,
      default: () => null,
    },
  },
  watch: {
    mapIndex: {
      handler(newData) {
        // 设置点的位置(经纬度)
        console.log("mapIndex", newData);
        const geoCoordMap = {
          公交公司: [104.973881, 25.779932, 10,],
          惠民大道: [104.947304,25.785784, 10],
          恵民小区: [104.946278,25.786978, 10],
          恒丰路段: [104.972696,25.777887, 20],
          环城东路: [104.96579,25.787922 ,20],
          政务中心侧: [104.946524,25.786228, 20],
          文笔路: [104.964276,25.788976, 20],
          农商行: [104.962301,25.790112, 20],
          交通路: [104.95324,25.78429, 20],
          公安路段: [104.97382,25.782571, 20],
        };
        let seriesData = [
          { name: "公交公司" },
          { name: "惠民大道", },
          { name: "恵民小区", },
          { name: "恒丰路段", },
          { name: "环城东路", },
          { name: "政务中心侧", },
          { name: "文笔路", },
          { name: "农商行", },
          { name: "交通路", },
          { name: "公安路段", },
        ];
        let convertData = function (data) {
          let scatterData = [];
          for (var i = 0; i < data.length; i++) {
            var geoCoord = geoCoordMap[data[i].name];
            if (geoCoord) {
              scatterData.push({
                name: data[i].name,
                value: geoCoord.concat(data[i].value),
              });
            }
          }
          console.log("scatterData", scatterData);
          return scatterData;
        };

        this.options = {
          showLegendSymbol: true,
           //鼠标移带地图显示弹窗
          tooltip: {
            trigger: "item",
            textStyle: {
              fontSize: 14,
              lineHeight: 22,
            },
            position: (point) => {
              // 固定在顶部
              return [point[0] + 50, point[1] - 20];
            },
            // 如果需要自定义 tooltip样式，需要使用formatter
            /*
              formatter: params => {
                return `<div style=""> ... </div>`
              }
            */
          },
          visualMap: {
            min: 0,
            max: 10,
            show: false,
            seriesIndex: 0,
            // 颜色
            inRange: {
              color: ["rgba(13,128,152, .2)", "rgba(41,166,206, .5)"],
            },
          },
          // 底部背景
          geo: {
            show: true,
            aspectScale: 0.85, //长宽比
            zoom: 1.2,
            top: "10%",
            left: "16%",
            map: 'map',//this.jsonMap[this.mapIndex].mapSite,
            roam: false,
            itemStyle: {
              normal: {
                areaColor: "rgba(0,0,0,0)",
                shadowColor: "rgba(7,114,204, .8)",
                shadowOffsetX: 5,
                shadowOffsetY: 5,
              },
              emphasis: {
                areaColor: "#00aeef",
              },
            },
            // tooltip: {
            //   show: false,
            // },
          },
          series: [
            {
              name: this.jsonMap[this.mapIndex].seriesName,
              type: "map",
              aspectScale: 0.85, //长宽比
              zoom: 1.2,
              mapType: this.jsonMap[this.mapIndex].mapSite, // 自定义扩展图表类型
              top: "10%",
              left: "16%",
              itemStyle: {
                normal: {
                  color: "red",
                  areaColor: "rgba(19,54,162, .5)",
                  borderColor: "rgba(0,242,252,.3)",
                  borderWidth: 1,
                  shadowBlur: 7,
                  shadowColor: "#00f2fc",
                },
                emphasis: {
                  areaColor: "#4f7fff",
                  borderColor: "rgba(0,242,252,.6)",
                  borderWidth: 2,
                  shadowBlur: 10,
                  shadowColor: "#00f2fc",
                },
              },
              //地图上城市名
              label: {
                formatter: (params) => `${params.name}`,
                show: true,
                position: "insideRight",
                textStyle: {
                  fontSize: 14,
                  color: "#efefef",
                },
                emphasis: {
                  textStyle: {
                    color: "#fff",
                  },
                },
              },
              data: this.jsonMap[this.mapIndex].cdata,
            },

            {
              type: "effectScatter",
              coordinateSystem: "geo",
              symbolSize: 7,
              effectType: "ripple",
              legendHoverLink: false,
              showEffectOn: "render",
              rippleEffect: {
                period: 4,
                scale: 2.5,
                brushType: "stroke",
              },

              zlevel: 1,
              label: {
                normal: {
                  show: true,
                  formatter: function (params) {
                    console.log("params", params);
                    return (
                      "{fline|泊位数" +
                      "}\n{tline|" +
                      (params.name + ":" + params.value[2]) +
                      "}"
                    );
                  },
                  position: "top",
                  backgroundColor: "rgba(0,0,0,.5)",
                  padding: [0, 0],
                  borderRadius: 3,
                  lineHeight: 32,
                  color: "#ffffff",
                  fontSize: 18,
                  rich: {
                    fline: {
                      padding: [0, 10, 10, 10],
                      color: "#ffffff",
                    },
                    tline: {
                      padding: [10, 10, 0, 10],
                      color: "#ffffff",
                    },
                  },
                },
                emphasis: {
                  show: true,
                },
              },
              itemStyle: {
                normal: {
                  color: "#99e2fe",
                  shadowBlur: 5,
                  shadowColor: "#fff",
                },
              },
              name: "泊位数",
              data: convertData(seriesData),
              tooltip: {},
            },
          ],
        };
        // 重新选择区域
        this.handleMapRandomSelect();
      },
      immediate: true,
      deep: true,
    },
  },
  mounted() {
    // this.$refs.centreLeft2.addEventListener(
    //   "mousewheel",
    //   this.throttle(this.handleScroll, 500)
    // );
    // this.$refs.centreLeft2.addEventListener(
    //   "DOMMouseScroll",
    //   this.throttle(this.handleScroll, 500)
    // );
  },
  methods: {
    // 开启定时器
    startInterval() {
      const _self = this;
      // 应通过接口获取配置时间，暂时写死5s
      const time = 2000;
      if (this.intervalId !== null) {
        clearInterval(this.intervalId);
      }
      this.intervalId = setInterval(() => {
        _self.reSelectMapRandomArea();
      }, time);
    },
    // 重新随机选中地图区域
    reSelectMapRandomArea() {
      const length = 9;
      this.$nextTick(() => {
        const map = this.$refs.centreLeft2ChartRef.chart;
        let index = Math.floor(Math.random() * length);
        while (index === this.preSelectMapIndex || index >= length) {
          index = Math.floor(Math.random() * length);
        }
        map.dispatchAction({
          type: "mapUnSelect",
          seriesIndex: 0,
          dataIndex: this.preSelectMapIndex,
        });
        map.dispatchAction({
          type: "showTip",
          seriesIndex: 0,
          dataIndex: index,
        });
        map.dispatchAction({
          type: "mapSelect",
          seriesIndex: 0,
          dataIndex: index,
        });
        this.preSelectMapIndex = index;
      });
    },
    handleMapRandomSelect() {
      this.$nextTick(() => {
        const map = this.$refs.centreLeft2ChartRef.chart;
        let _self = this;
        // setTimeout(() => {
        //   _self.reSelectMapRandomArea();
        // }, 0);
        // 移入区域，清除定时器、取消之前选中并选中当前
        map.on("mouseover", function (params) {
          // clearInterval(_self.intervalId);
          // map.dispatchAction({
          //   type: "mapUnSelect",
          //   seriesIndex: 0,
          //   dataIndex: _self.preSelectMapIndex,
          // });
          // map.dispatchAction({
          //   type: "mapSelect",
          //   seriesIndex: 0,
          //   dataIndex: params.dataIndex,
          // });
          // _self.preSelectMapIndex = params.dataIndex;
        });
        // 移出区域重新随机选中地图区域，并开启定时器
        map.on("globalout", function () {
          // console.log("globalout");
          // _self.reSelectMapRandomArea();
          // _self.startInterval();
        });
        // _self.startInterval();
        //echarts的实例对象添加click事件
        map.on("click", function (res) {
          let mapValue = res.name;
          _self.jsonMap.map((item, index) => {
            if (item.mapSite == mapValue) {
              _self.$emit("change", index);

              _self.$forceUpdate();
            }
          });
        });
      });
    },
    //滚轮返回地图上一级
    // handleScroll(e) {
    //   let direction = e.deltaY > 0 ? "down" : "up"; //deltaY为正则滚轮向下，为负滚轮向上

    //   if (direction == "up") {
    //     let mapIndex = this.mapIndex;

    //     if (mapIndex !== 0) {
    //       mapIndex--;
    //       this.$emit("change", mapIndex);
    //       this.$forceUpdate();
    //     }
    //   }
    // },
    throttle(func, delay) {
      var timer = null;
      return function () {
        var context = this;
        var args = arguments;
        if (!timer) {
          timer = setTimeout(function () {
            func.apply(context, args);
            timer = null;
          }, delay);
        }
      };
    },
  },
};
</script>
