<template>
  <div id="index">
    <dv-full-screen-container class="bg">
      <dv-loading v-if="loading">Loading...</dv-loading>
      <div v-else class="host-body">
        <div
            class="d-flex jc-center"
            style="height: 3.5%; margin-bottom: 0.15rem"
        >
          <dv-decoration-10 style="width: 33.3%; height: 0.0625rem"/>
          <div class="d-flex jc-center">
            <dv-decoration-8
                :color="['#568aea', '#000000']"
                style="width: 2.5rem; height: 0.625rem"
            />
            <div class="title">
              <span class="title-text">城市级智慧停车大数据平台</span>
              <dv-decoration-6
                  class="title-bototm"
                  :reverse="true"
                  :color="['#50e3c2', '#67a1e5']"
                  style="width: 3.125rem; height: 0.1rem"
              />
            </div>
            <dv-decoration-8
                :reverse="true"
                :color="['#568aea', '#000000']"
                style="width: 2.5rem; height: 0.625rem"
            />
          </div>
          <dv-decoration-10
              style="width: 33.3%; height: 0.0625rem; transform: rotateY(180deg)"
          />
        </div>

        <!-- 第二行 -->
        <div class="d-flex jc-between px-2" style="height: 3.5%">
          <!--          <div class="d-flex" style="width: 40%">-->
          <!--            <div-->
          <!--              class="react-right ml-4"-->
          <!--              style="-->
          <!--                width: 6.25rem;-->
          <!--                text-align: left;-->
          <!--                background-color: #0f1325;-->
          <!--              "-->
          <!--            >-->
          <!--              <span class="react-before"></span>-->
          <!--              <span class="text">黔西南州项目</span>-->
          <!--            </div>-->
          <div class="select">
            <el-select v-model="routerUrl" placeholder="请选择项目" @change="routerChange" clearable>
              <el-option
                  v-for="item in routerList"
                  :key="item.url"
                  :label="item.label"
                  :value="item.url">
              </el-option>
            </el-select>
          </div>
          <div class="react-right ml-3" style="background-color: #1a5cd7;margin-left: -2rem">
            <!-- <span
              class="text colorBlue"
              @click="$router.push({ name: 'view2' })"
              >更多</span
            > -->
            <span class="text fw-b"></span>
          </div>
          <!--          </div>-->
          <div style="width: 40%" class="d-flex">
            <div class="react-left bg-color-blue mr-3">
              <!-- <span class="text fw-b">ZY-IOT</span> -->
            </div>
            <div
                class="react-left mr-4"
                style="
                width: 6.25rem;
                background-color: #0f1325;
                text-align: right;
              "
            >
              <span class="react-after"></span>
              <span class="text"
              >{{ dateYear }} {{ dateWeek }} {{ dateDay }}</span
              >
            </div>
          </div>
        </div>

        <div class="body-box" style="margin-top: 0.3rem">
          <!-- 第三行数据 -->
          <div class="content-box">
            <div style="flex: 1">
              <dv-border-box-12>
                <centerLeft1
                    :allData="allData.order_detail"
                    :allData2="order_detail_down"
                />
              </dv-border-box-12>
            </div>
            <div style="flex: 1" v-if="numTotal != 0">
              <dv-border-box-12>
                <centerLeft2 :marker="marker" :numTotal="numTotal"/>
              </dv-border-box-12>
            </div>
            <!-- 中间 -->
            <div style="flex: 2">
              <center
                  :titleItem="
                  allData.user_list && allData.user_list.titleItem
                    ? allData.user_list.titleItem
                    : ''
                "
                  :ranking="park_one_range"
                  :rate="
                  allData.pay_num && allData.pay_num.rate
                    ? allData.pay_num.rate
                    : ''
                "
              />
            </div>
            <!-- 中间 -->
            <!-- <div>
              <centerRight2 />
            </div> -->
            <div style="flex: 1">
              <dv-border-box-13>
                <centerRight1 :config="config"/>
              </dv-border-box-13>
            </div>
          </div>

          <!-- 第四行数据 -->
          <div class="bototm-box">
            <dv-border-box-13>
              <bottomLeft :device_use="allData.device_use"/>
            </dv-border-box-13>
            <dv-border-box-12>
              <bottomRight :park_space_pic="allData.park_space_pic"/>
            </dv-border-box-12>
          </div>
        </div>
      </div>
    </dv-full-screen-container>
  </div>
</template>

<script>
let timer = {};
import {formatTime} from "../utils/index.js";
import centerLeft1 from "./centerLeft1";
import centerLeft2 from "./centerLeft2";
import centerRight1 from "./centerRight1";
// import centerRight2 from "./centerRight2";
import center from "./center";
import bottomLeft from "./bottomLeft";
import bottomRight from "./bottomRight";
import {allData} from "../common/allData";
//data
export default {
  data() {
    return {
      loading: true,
      dateDay: null,
      dateYear: null,
      dateWeek: null,
      weekday: ["周日", "周一", "周二", "周三", "周四", "周五", "周六"],
      allData: {},
      order_detail_down: {},
      config: {},
      park_one_range: {},
      marker: {},
      numTotal: 0,
      routerUrl: '',
      routerList: [{
        url: 'https://platformzf.syzytech.com/onemap',
        label: '贞丰智慧停车平台'
      },
        {
          url: 'https://pacloud.syzytech.com/onemap',
          label: '册亨智慧停车平台'
        },
        {
          url: 'https://platformpa.syzytech.com/onemap',
          label: '普安智慧停车平台'
        },
        {
          url: 'https://jle.syzytech.com/onemap',
          label: '将乐智慧停车平台'
        },
        {
          url: 'https://hlai.syzytech.com/onemap',
          label: '怀来智慧停车平台'
        },
        {
          url:'https://pacloudtest.syzytech.com/onemap',
          label:'海拉尔区智慧停车平台'
        },
        {
          url:'https://pacloudtest.syzytech.com/onemap',
          label:'扎兰屯市智慧停车平台'
        },
        {
          url:'https://pacloudtest.syzytech.com/onemap',
          label:'枞阳县智慧停车平台'
        },
        {
          url:'https://pacloudtest.syzytech.com/onemap',
          label:'武城县智慧停车平台'
        },
        {
          url:'https://pacloudtest.syzytech.com/onemap',
          label:'昭平县智慧停车平台'
        },
        {
          url:'https://pacloudtest.syzytech.com/onemap',
          label:'西林县智慧停车平台'
        }
      ]
    };
  },
  components: {
    centerLeft1,
    centerLeft2,
    centerRight1,
    // centerRight2,
    center,
    bottomLeft,
    bottomRight,
  },
  mounted() {
    this.timeFn();
    this.cancelLoading();
    this.getData();

    setInterval(() => {
      this.changeNumber();
    }, 3000);
  },
  // beforeDestroy() {
  //   clearTimeout(timer);
  // },
  methods: {
    getData() {
      let that = this;
      let datas = {};
      this.$http(
          "https://pacloudtest.syzytech.com/smart-park/sys/awareness/statisticalAll"
      )
          .then((res) => {
            if (res && res.code == 200) {
              this.allData = allData;
              this.config = allData.car_user_info.config;
              this.park_one_range = allData.park_one_range;
              this.order_detail_down = allData.order_detail_down;

              return;
            } else {
              timer = setTimeout(() => {
                this.getData();
              }, 60000);
            }
            datas = res.data.data;
            this.numTotal = datas.total_park_space;
            // console.log("???????????", this.numTotal);
            if (
                JSON.stringify(this.order_detail_down) !==
                JSON.stringify(datas.order_detail_down)
            ) {
              this.order_detail_down = datas.order_detail_down;
            }

            //支付指数静态color数据
            let colorArray = [
              {
                textStyle: "#3fc0fb",
                series: {
                  color: ["#00bcd44a", "transparent"],
                  dataColor: {
                    normal: "#03a9f4",
                    shadowColor: "#97e2f5",
                  },
                },
              },
              {
                textStyle: "#67e0e3",
                series: {
                  color: ["#faf3a378", "transparent"],
                  dataColor: {
                    normal: "#ff9800",
                    shadowColor: "#fcebad",
                  },
                },
              },
              {
                textStyle: "#67e0e3",
                series: {
                  color: ["#10556b", "transparent"],
                  dataColor: {
                    normal: "#59c4e6",
                    shadowColor: "#a5e7f0",
                  },
                },
              },
              {
                textStyle: "#67e0e3",
                series: {
                  color: ["#144a27", "transparent"],
                  dataColor: {
                    normal: "#9fe5b8",
                    shadowColor: "#cdf2da",
                  },
                },
              },
            ];

            datas.car_user_info.config = {
              ...datas.car_user_info.config,
              rowNum: 6, //表格行数
              headerHeight: 35,
              headerBGC: "#0f1325", //表头
              oddRowBGC: "#0f1325", //奇数行
              evenRowBGC: "#171c33", //偶数行
              index: true,
              columnWidth: [50, 150, 80, 100, 100],
              align: ["center", "center", "center", "center", "center"],
            };

            //车位使用情况静态数据绑定
            if (
                JSON.stringify(this.config) !==
                JSON.stringify(datas.car_user_info.config)
            ) {
              this.config = {
                ...datas.car_user_info.config,
                rowNum: 6, //表格行数
                headerHeight: 35,
                headerBGC: "#0f1325", //表头
                oddRowBGC: "#0f1325", //奇数行
                evenRowBGC: "#171c33", //偶数行
                index: true,
                columnWidth: [50, 150, 80, 100, 100],
                align: ["center", "center", "center", "center", "center"],
              };

              datas.car_user_info.config.data.map((item, index) => {
                let name = item[0];
                let minLen = Math.ceil(
                    Number(item[1]) * parseFloat(item[2]) * 0.01
                ); //剩余车位
                that.marker[name] = [];
                for (let i in datas.map_park_lot_location) {
                  if (name == i + "停车点") {
                    that.marker[name] = datas.map_park_lot_location[i];
                  }
                }
                that.marker[name][2] = item[1];
                that.marker[name][3] = item[1] - minLen;
                that.marker[name][4] = minLen;
              });
            }

            if (
                JSON.stringify(this.park_one_range) !==
                JSON.stringify(datas.park_one_range)
            ) {
              this.park_one_range = datas.park_one_range;
            }

            //支付指数静态数据绑定
            if (datas.pay_num && datas.pay_num.rate) {
              datas.pay_num.rate.map((item, index) => {
                item.colorData = {};
                if (colorArray[index]) {
                  item.colorData = colorArray[index];
                } else {
                  item.colorData = colorArray[0];
                }
              });
            }
            this.allData = datas;
          })
          .catch((err) => {
            this.allData = allData;
            this.config = allData.car_user_info.config;
            this.park_one_range = allData.park_one_range;
            this.order_detail_down = allData.order_detail_down;
          });
    },
    timeFn() {
      setInterval(() => {
        this.dateDay = formatTime(new Date(), "HH: mm: ss");
        this.dateYear = formatTime(new Date(), "yyyy-MM-dd");
        this.dateWeek = this.weekday[new Date().getDay()];
      }, 1000);
    },
    routerChange(e) {
      if(e){
        window.open(e)
        this.routerUrl = ''
      }
    },
    changeNumber() {
      // this.order_detail_down.numberData.forEach((item, index) => {
      //   item.number.number[0] += ++index;
      //   item.number = { ...item.number };
      // });
    },
    cancelLoading() {
      setTimeout(() => {
        this.loading = false;
      }, 500);
    },
  },
};
</script>

<style lang="scss" scoped>
@import "../assets/scss/index.scss";

.select {
  &::v-deep {
    .el-select {
      // 1.修改边距
      margin-left: 9px;

      .el-input.el-input--suffix {
        // 2.修改背景颜色、字体颜色、边框、宽高
        .el-input__inner {
          background: #1a5cd7;
          color: #fff;
          border: 1px solid #1a5cd7;
        }
      }

      // 符号的位置会错乱，进行修正（垂直）
      .el-select__caret.el-input__icon.el-icon-arrow-up {
        line-height: 30px;
      }
    }
  }
}
</style>
